<template>
	<div>
		<div class="" :style="{ height: containerHeight }">
			<div class="cateCotainer" style="background-color: #fff;color: #333;font-size: 13px;"> 
				<div style="overflow: auto;  display: flex;padding-bottom: 2px; ">
					<template v-for="(item,index) in categoryList">
						<span class="firstLevelItem" :class="{selected:activeCate1==index}" @click="onClickLevel1Item(item,index)" v-text="item.name"></span>
					</template>
				</div>
				<div v-if="categoryList.length>0&&activeCate1Data.childList" style="display: flex;flex-direction: row;flex-wrap: wrap;" >
					<div class="secondLevelItem" :class="{selected:cate2Id==''}" @click="onClickLevel2Item({},-1)">
						<div class="secondLevelImgDiv">
							<img :src="getImgUrl(activeCate1Data.img)"/>
						</div>
						<span class="secondLevelItemText" >全部商品</span>	
					</div>
					<template  v-for="(child,childIndex) in activeCate1Data.childList">
						<div class="secondLevelItem" :class="{selected:cate2Id==child.id}" @click="onClickLevel2Item(child,childIndex)">
							<div class="secondLevelImgDiv">
								<img :src="getImgUrl(child.img)"/>
							</div>
							<span class="secondLevelItemText" >{{child.name}}</span>	
						</div>
						
					</template>
				</div>
			</div>
			<div class="goodsCantainer">
			    <van-search style="" v-model="keyword" @focus="onKeyswordFocus()" shape="round" placeholder="" show-action >
			        <template #action>
			            <div @click="onSearch">搜索</div>
			        </template>
			    </van-search>
			    <div style="position: relative" v-show="false" >
			        <van-dropdown-menu style="width:50%" class="leftSortMenu">
			            <van-dropdown-item v-model="defaultSort" :options="sortList" @change="updateSort" @click.capture="switchToDefaultSort" />
			        </van-dropdown-menu>
			        <div class="saleNumSort" :style="{color:sort=='saleNum'?'red':'black'}" @click="updateSort('saleNum')">销量排序</div>
			    </div>
			    <div :style="containerCss" style="overflow: auto;margin-top:12px;">
			        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="100" @load="refresh">
			            <template #default>
			                <van-grid :column-num="2" :gutter="5" >
			                    <van-grid-item v-for="goods in goodsList" :to="'/goodsInfo?goodsId='+goods.goodsId+'&instanceId='+goods.instanceId">
			                        <div style="position: relative;" :style="{width:goodsLogoSize,height:goodsLogoSize}">
			                            <van-tag type="danger" style="position: absolute;top:0;">{{goods.goodsTag}}</van-tag>
			                            <van-tag type="danger" style="position: absolute;top:0;right:0;">{{goods.brand}}</van-tag>
			                            <img :src="getImgUrl(goods.goodsLogo)" style="width: 100%;height: 100%;border-radius: 4px;">
			                        </div>
									<div style="text-align: left;color:#333;width: 100%;margin-top:6px;font-size: 14px;">
										<div class="van-multi-ellipsis--l2">{{goods.goodsRemark}}</div>
										<div class="van-multi-ellipsis--l2" style="font-size: 13px;color:#999;margin:5px 0;">
											{{getInstanceDesc(goods,true)}}
										</div>
										<div class="flex-between" @click.stop="" style="font-size: 12px;color:#999;margin:5px 0;justify-content: flex-end;">
											<!-- <span style="position: relative;top:-2px;">{{goods.viewNum}}人浏览</span> -->
											
											<div class="flex-between" style="color:#333;">
												<div style="padding:5px;background-color: #f2f3f5;"><van-icon name="minus" @click.stop="addCartNum(goods,-1)"/></div>
												<span style="padding:5px ;min-width:20px;text-align:center;;margin: 0 2px;;;background-color: #f2f3f5;">{{goods.cartNum}}</span>
												<div style="padding:5px;background-color: #f2f3f5;"><van-icon name="plus" @click.stop="addCartNum(goods,1)"/></div>
											</div>
										</div>
										<div class="flex-between" style="font-size: 12px;color:#999;">
											<!-- <span style="position: relative;top:-2px;">{{goods.viewNum}}人浏览</span> -->
											<!-- <span style="position: relative;text-decoration: line-through;">¥ {{qy.formatMoney(goods.goodsPrice)}}</span> -->
											<span style="font-size:12px;color:red;"><span style="font-size:12px;color:red;">¥ </span>
											{{qy.formatMoney(goods.wholesalePrice,'面议')}}</span>
											<span style="">库存：{{goods.stockNum}}</span>
										</div>
										<div style="font-size: 12px;color:#888;margin-top:5px;">
											<span @click.stop="navigateTo('/shop?id='+goods.shopId)">{{goods.shopName}} <span style="font-size: 14px;;">></span></span>
										</div>
									</div>
			                    </van-grid-item>
			                </van-grid>
			            </template>
			        </van-list>
			    </div>
			    <!-- <van-overlay :show="true"  >
			        <van-loading type="spinner" />
			    </van-overlay> -->
			</div>
		</div>
		<bottomTabNav :index="2"></bottomTabNav>
	</div>
</template>
<script type="text/javascript">
import bottomTabNav from '@/components/bottomTabNav';

import request from '@/utils/request';

export default {
	// name: "message",
	components: { bottomTabNav },
	data() {
		return {
			activeCate1: 0,
			containerHeight: 0,
			img: require('@/assets/images/empty.png'),
			categoryList: [],
			//商品
			keyword: "",
			active: 0,
			defaultSort: "",
			sort: "",
			loading: false,
			finished: false,
			pageNum: 1,
			pageSize: 10,
			isFirstInput:true,
			containerHeight: 0,
			factoryName: "销量",
			isRequesting: false,
			factoryList: [
			    { text: "销量", value: "销量" }
			],
			sort: "",
			sortList: [
			    { text: '综合排序', value: '' },
			    { text: '价格最低', value: 'minPrice' },
			    { text: '价格最高', value: 'maxPrice' },
			],
			proxyId:0,
			cate1Id:"",
			cate2Id:"",
			goodsList: [],
			goodsLogoSize: 0
		};
	},
	computed: {
	    activeCate1Data(){
			return this.categoryList&&this.categoryList[this.activeCate1];
		},
		containerCss(){
			let css={};
			css['minHeight']=this.containerHeight;
			return css;
		}
	},
	created() {
		this.fetchCateList();
		this.hideShare();
		this.goodsLogoSize = document.documentElement.clientWidth / 2 - 25 + "px";
		let otherHeight = 70;		
		let proxyStg = this.storage.get("proxyStg");
		if (proxyStg) {
			this.proxyId = JSON.parse(proxyStg).id;
		}
		this.containerHeight = document.documentElement.clientHeight - otherHeight + "px";
	},
	methods: {
		onClickLevel1Item(item,index){
			this.cate1Id=item.id||"";
			this.cate2Id="";
			this.activeCate1=index;
			this.onSearch();
		},
		onClickLevel2Item(item,childIndex){
			this.cate2Id=item.id||"";
			this.onSearch();
		},
		onSearch() {
		    this.reset();
		    this.refresh();
		},
		onKeyswordFocus:function(){
			if(this.isFirstInput){
				this.isFirstInput=false;
				this.keyword="";
			}
		},
		switchToDefaultSort() {
		    this.sort = this.defaultSort
		},
		updateSort(value) {
		    //console.log(value)
		    if (this.sort == value) {
		        return;
		    }
		    this.reset();
		    this.sort = value;
		    this.refresh();
		},
		reset() {
		    this.pageNum = 1;
		    this.goodsList = [];
		    this.finished = false;
		},
		refresh() {
		    if (this.isRequesting) {
		        return;
		    } else {
		        this.isRequesting = true;
		    }
		    request.post("/api/goods/list", {
		        sort: this.sort,
				proxyId:this.proxyId,
		        keyword: this.keyword,
		        shopId: this.shopId || "",
				cateId:this.cate2Id||this.cate1Id,
		        pageNum: this.pageNum++,
		        pageSize: this.pageSize
		
		    }).then(rs => {
		        this.loading = false;
		        this.isRequesting = false;
		        if (rs.rows.length < this.pageSize||rs.total<=(this.pageNum-1)*this.pageSize) {
		            this.finished = true;
		        }
		
		        this.goodsList = this.goodsList.concat(rs.rows);
		    });
		},
		gotoShop(id) {
		    this.$router.push({ path: "/shop", query: { id: id } })
		},
		fetchCateList() {
			request.post('/api/category/list').then((rs) => {
				rs.forEach((item) => {
					if (item.banner) {
						item.banner = item.banner.split(',');
					}
				});
				let cateList= rs.sort((o1, o2)=> {
					return o1.sortNum - o2.sortNum;
				});
				cateList.splice(0,0,{
					name:"全部商品"
				})
				this.categoryList=cateList;
			});
		}
	},
	watch: {},
	mounted() {
		this.containerHeight = document.documentElement.clientHeight - 50 + 'px';
	}
};
</script>
<style type="text/css" scoped="">
.categoryBody {
	position: relative;
}

.my-swipe {
	padding-top: 20px;
	margin-right: 20px;
}

.van-swipe-item {
	text-align: center;
}

.van-swipe-item img {
	height: 120px;
	width: 100%;
}
.firstLevelItem {
	padding: 4px 6px 6px;
	font-size: 14px;
	flex-shrink: 0;
	margin: 2px 4px;
}
.firstLevelItem.selected{
	color:#EE0A24;
	font-weight: bold;
	    border-bottom: 3px solid #EE0A24;
}
.secondLevelItem{
	padding: 4px 6px;
	font-size: 14px;
	flex-shrink: 0;
	margin: 2px 4px;
	/* height: 60px; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.secondLevelItem.selected .secondLevelItemText{
	color:#fff;
	background-color: #EE0A24;
	border-radius: 4px;
}
.secondLevelItem .secondLevelImgDiv{
	width: 50px;
	height: 50px;
	border-radius: 50px;
	overflow: hidden;
}
.secondLevelItem img{
	width: 100%;
	height: 100%;
}
.secondLevelItemText{
	font-size: 12px;
	color:#333;
	padding:2px 4px;
	position: relative;
	top:2px;
	text-align: center;
	
}
</style>
<style type="text/css">

</style>
